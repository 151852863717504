import React, { memo } from 'react';
import PropTypes from 'prop-types';



const DefaultProgress = ({ progress, textLabel, labelText, subLabel, value, className,
    labelTextClassName, progressClassName, thickness = "h-4" }) => (<>
        <div className={`flex items-end justify-between mb-4`}>
            <div className={`flex items-end gap-4 sm:gap-x-5.5 ${className}`}>
                <h2 className={`font-bold text-heading-5 sm:text-heading-2 text-neutral-1000`}>{labelText}</h2>
                <div className={`text-neutral-800 `}>
                    <p className={`font-normal text-sm/4 ${labelTextClassName}`}>{subLabel}</p>
                    <p className={`text-heading-7 sm:text-heading-5 ${labelTextClassName}`}>{textLabel}</p>
                </div>
            </div>
            <p className={`text-heading-6 sm:text-heading-4 text-neutral-1000 ${progressClassName}`}>{value}</p>
        </div>
        <div className={`w-full ${thickness} rounded-full bg-neutral-300 `}>
            <div className={`${thickness}  bg-gradient-to-r from-green-200 to-green-300 rounded-full`} style={{ width: `${progress === '0%' ? '0px' : `${progress}%`}` }}></div>
        </div></>)



const WhiteBorder = ({ progress, textLabel, labelText, subLabel,
    value, className, labelTextClassName,
    progressClassName, thickness = "h-4" }) => (<>    <div className={`flex items-center justify-between`}>
        <div className={`flex items-center gap-x-5.5 ${className}`}>
            <h2 className={`font-bold text-heading-2 text-neutral-1000`}>{labelText}</h2>
            <div className={`text-neutral-800 `}>

                <p className={`text-heading-5 ${labelTextClassName}`}>{textLabel}</p>
            </div>
        </div>
        <p className={`text-heading-4 text-neutral-1000 ${progressClassName}`}>{value}</p>
    </div>
        <div className={`w-full ${thickness} border-8 relative border-white flex items-center rounded-[10px] bg-white `}>
            <p className={`font-normal  text-sm/4 absolute left-5 ${parseInt(String(progress)?.slice(0, -1)) > 25 ? "text-neutral-100" : "text-secondary-600"} ${labelTextClassName}`}>{subLabel}</p>
            <div 
            className={`h-full p-2 ${
                progress === '0%'
                  ? "bg-gradient-to-r from-white to-white"
                  : "bg-gradient-to-r from-green-200 to-green-300"
              } rounded-[10px]`}
            style={{ width: `${progress === '0%' ? '0px' : `${progress}%`}` }}></div>
        </div></>)


export const ProgressBar = memo((props) => {
    const { variant = "default" } = props;

    const variants = {
        default: <DefaultProgress {...props} />,
        "white-border": <WhiteBorder  {...props} />
    }

    return (
        <>
            {variants[variant]}
        </>
    );
});

ProgressBar.propTypes = {
    progress: PropTypes.string,
    textLabel: PropTypes.string,
    labelText: PropTypes.string,
    className: PropTypes.string,
    subLabel: PropTypes.string,
    labelTextClassName: PropTypes.string,
    progressClassName: PropTypes.string
};

ProgressBar.defaultProps = {
    progress: "",
    textLabel: "",
    labelText: "",
    className: "",
    subLabel: "",
    labelTextClassName: "",
    progressClassName: ""
};
