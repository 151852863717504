import React, { useEffect, useState } from 'react';
import { Button, Typography } from '../../../../Components';
import CurrencySelector from '../../../../Components/CurrencySelector';
import { useDispatch, useSelector } from 'react-redux';
import { useShowToast } from '../../../../Utils/toast';
import { updateCartItems } from '../../../../Redux/cartReducer';
import { useMutation } from '@apollo/client';
import { ADD_TO_BASKET } from '../../../../GraphQL/Queries/checkout';
import { AlertCircle } from '../../../../Theme/icons';
import { currencies, uniqueElements } from '../../../../Utils/common';

const DonationAmount = ({ project = {}, payment, setPayment, schema, currentStep, toggleModal, updatePaymentStatus, isPaymentCompleted, isAnonymous = false, goalAmountCurrency }) => {
    const showToast = useShowToast();
    const dispatch = useDispatch();
    const { user = null } = useSelector(state => state?.auth) || { user: null };
    const { cartItems } = useSelector(state => state?.cart)
    const [projectDetails, setProjectDetails] = useState(project || {})
    const { rewards = [] } = projectDetails || { rewards: [] }
    const [addItemsintoBasketAPI, { loading }] = useMutation(ADD_TO_BASKET)
    const [errorMessage, setErrorMessage] = useState('')
    const [selectIndex, setselectIndex] = useState(-1);
    const [rewardSlice, setRewardSlice] = useState(2)

    const options = user === null ? [{ id: 1, name: 'One Time' },] : [
        { id: 1, name: 'One Time' },
        { id: 2, name: 'Recurring' }
    ];

    const [selectedOption, setSelectedOption] = useState(1);

    const handleOptionSelection = (option) => {
        setSelectedOption(option?.id);
        setPayment({ ...payment, isRecurring: option?.id === 2 });

    };

    const onAmountChange = async (amountText) => {
        if (amountText) {
            await schema.validate({ amount: amountText?.trim() })
                .then((res) => {
                    setPayment({ ...payment, amount: amountText })
                    setErrorMessage("")
                })
                .catch(err => {
                    setErrorMessage(err?.message)
                });
        } else {
            setPayment({ ...payment, amount: 0 })
            setErrorMessage("Amount is invalid")
        }
    }

    const handleChooseRewardCurrency = (index, amount, id) => {
        setselectIndex(index)
        setPayment({ ...payment, amount: parseFloat(amount), currency: "USD" })
        setErrorMessage("")
    }

    const onChangeCurrency = (currency) => {

        setPayment({ ...payment, currency })
    }

    const handleSelectRecurrinType = (e, type, periodDays) => {
        e.preventDefault();
        setPayment({ ...payment, type, periodDays })
    }

    const addItemsintoBasket = async (items) => {

        const peroidDays = {
            "Monthly": 30,
            "Weekly": 7,
            "Yearly": 365
        }

        const payload = {
            ...items,
            rewardItemId: items?.rewardItemId || null,
            quantity: 1,
            type: items?.rewardItemId ? "REWARD" : "CUSTOM_AMOUNT",
            periodDays: items?.isRecurring ? peroidDays[items?.type] : 1,
            amount: parseFloat(items?.amount),
            projectId: String(items?.id),
            amountCurrency: items?.currency
        }


        const uniqueCartItems = uniqueElements([...[{ ...items, ...payload }], ...cartItems])


        try {
            const { data } = await addItemsintoBasketAPI({ variables: payload })
            if (data?.addToBasket) {
                showToast({ message: "Added cart successfully", variant: 'success' })
            }
            dispatch(updateCartItems(uniqueCartItems));
            const cartButton = document?.querySelector('#cart-button');
            cartButton?.click();
            toggleModal();

        } catch (error) {
            showToast({ message: error.message, variant: 'error' })
        }
    }

    const handleContinueClick = React.useCallback(async (e) => {
        e.preventDefault()
        const cartPayLoad = {
            ...projectDetails,
            ...payment,
        }
        const { currency = "" } = (cartItems?.length && cartItems?.at(0)) || { currency: "" }
        if (currency === '' || currency === payment?.currency) {
            const uniqueCartItems = uniqueElements([...[{
                ...cartPayLoad,
                amount: parseFloat(cartPayLoad?.amount),
                projectId: String(cartPayLoad?.id),
                amountCurrency: cartPayLoad?.currency,
                id: String(cartPayLoad?.id),
            },], ...cartItems])
            const activeProject = projectDetails?.status === "ACTIVE";

            if (activeProject && Number(cartPayLoad?.amount) > 0) {
                if (user) {
                    addItemsintoBasket(cartPayLoad)
                } else {
                    localStorage.setItem('cart', JSON.stringify(uniqueCartItems));
                    dispatch(updateCartItems(uniqueCartItems))
                    showToast({ message: "Added cart successfully", variant: 'success' })
                    const cartButton = document?.querySelector('#cart-button');
                    cartButton?.click();
                    toggleModal();
                }
            } else {
                !activeProject ? showToast({ message: "This projeect is not active. Please choose another one!", variant: 'info' }) :
                    showToast({ message: "Please enter valid  amount", variant: 'error' })
            }
        } else {
            showToast({ message: `You have chosen currency ${currency} for your first cart item. For upcoming items, please use the same currency.`, variant: 'info' })
        }
        //eslint-disable-next-line
    }, [project, payment, currentStep])

    const handleClose = (e) => {
        e.preventDefault();
        toggleModal()

    }

    const rewardCurrency = '$'
    // (allcurrencies?.length && allcurrencies?.find(country => country?.currency?.code === payment?.currency)?.currency?.symbol) || payment?.currency || ''

    const handleViewMoreRewards = () => setRewardSlice(rewards?.length)


    useEffect(() => {
        if (JSON.stringify(projectDetails) !== JSON.stringify(project)) {
            setProjectDetails(project);
        }
        return () => {
            updatePaymentStatus({
                ...isPaymentCompleted,
                status: false
            })
        }

        //eslint-disable-next-line
    }, [project])

    return (
        <form className="flex flex-col gap-5 md:gap-7.5">
            <div>
                <Typography variant="authText" className="mb-2 text-neutral-1000" text="Enter your donation amount" />
                <CurrencySelector selectedCurrency={payment?.currency}
                    amount={payment?.amount} onChange={onChangeCurrency}
                    onAmountChange={onAmountChange} currencies={currencies}
                    placeHolder="Amount" />
                {errorMessage && <div className="text-red-300 text-caption-text text-left mt-2 gap-1 flex items-start">
                    <div><AlertCircle className="mr-1" /></div>
                    {errorMessage}
                </div>}
                <div className="flex items-center gap-4 mt-4">
                    {!!options?.length && options.map((option) => (
                        <div key={option.id} className="flex items-center gap-2">
                            <input
                                type="radio"
                                id={`option-${option.id}`}
                                className="w-5 h-5 cursor-pointer form-radio text-neutral-400"
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={() => handleOptionSelection(option)}
                            />
                            <label htmlFor={`option-${option.id}`} className="sr-only">{option.name}</label>
                            <div className="text-sm font-normal text-neutral-700">
                                <Typography variant="authText" className="!font-medium text-sm text-neutral-1000" text={option.name} />
                            </div>
                        </div>
                    ))}
                </div>
                {selectedOption === 2 && (
                    <div className="grid grid-cols-3 gap-4 pt-4 mt-4 border-t border-neutral-400">
                        <Button variant="primary-outline" className={payment?.type === "Weekly" ? "bg-primary-200" : ""} onClick={(e) => handleSelectRecurrinType(e, "Weekly", 7)} label="Weekly" iconClassName="hidden" />
                        <Button variant="primary-outline" className={payment?.type === "Monthly" ? "bg-primary-200" : ""} onClick={(e) => handleSelectRecurrinType(e, "Monthly", 30)} label="Monthly" iconClassName="hidden" />
                        <Button variant="primary-outline" className={payment?.type === "Yearly" ? "bg-primary-200" : ""} onClick={(e) => handleSelectRecurrinType(e, "Yearly", 365)} label="Yearly" iconClassName="hidden" />
                    </div>
                )}
            </div>

            {!!rewards?.length && <div className='flex flex-col gap-5'>
                <Typography variant="heading7" className="text-center text-neutral-800" text="Rewards" />
                <div className='flex flex-col gap-4 h-full max-h-[30vh] overflow-y-auto'>
                    {rewards?.slice(0, rewardSlice)?.map((reward, i) => (
                        <div key={reward.id} onClick={() => handleChooseRewardCurrency(i, reward?.amount, reward?.id)} role="button" className={`flex items-center gap-4 p-3 border rounded-lg border-neutral-400 ${i === selectIndex ? 'bg-primary-100' : ''}`}>
                            <img className="w-15" src={reward?.imageUrl || '/assets/images/reward-tile.svg'} alt={reward.name}
                                onError={
                                    (e) => e.target.src = "/assets/images/no-image-available.svg"
                                }
                            />
                            <div className="flex flex-col gap-1 pe-3">
                                <Typography variant="heading7" text={reward.name} />
                                <Typography variant="heading7" text={`${rewardCurrency} ${reward.amount?.toLocaleString()}`} />
                            </div>
                        </div>
                    ))}
                </div>
                {(rewards?.length > 2 && rewards?.length !== rewardSlice) && <Button
                    onClick={handleViewMoreRewards}
                    type="button"
                    variant="primary-text"
                    label="View more rewards"
                    iconClassName="hidden"
                    className="mx-auto xl:mt-10"
                />}
            </div>}
            <div className='flex flex-col gap-5'>
                <Button
                    variant="primary"
                    loader={loading} 
                    label={"Add to Cart"}
                    disabled={Boolean(errorMessage?.length) || loading}
                    iconClassName="hidden"
                    className="w-full"
                    onClick={handleContinueClick}
                />
                <Button
                    variant="primary-text"
                    disabled={false}
                    loader={false}
                    label="Close"
                    iconClassName="hidden"
                    className="w-full"
                    onClick={(e) => handleClose(e)}
                />
            </div>
        </form>
    );
};

export default DonationAmount;
